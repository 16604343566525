import { Directive, ElementRef, HostBinding, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromUser from 'src/app/_store/_reducers';

export class CanAccessBase {
  constructor(protected store: Store<fromUser.State>) {}

  canAccessRessource(accessType: string): Observable<boolean> {
    return this.store.pipe(select(fromUser.getMp)).pipe(
      map((value) => {
        if (accessType === 'dashboard') {
          return value !== undefined && value !== null && value.mStatus !== 9;
        } else if (accessType === 'whenCommissioned') {
          return value && value.mStatus > 4;
        }
      })
    );
  }
}

@Directive({ selector: '[appCanAccess]' })
export class CanAccessDirective extends CanAccessBase implements OnDestroy, OnInit {
  @Input() accessType: string;
  sub: Subscription;
  hasView = false;

  @HostBinding('disabled')
  disabled = true;

  constructor(private el: ElementRef, store: Store<fromUser.State>) {
    super(store);
  }

  @Input() disabledClass: string[];

  ngOnInit(): void {
    this.sub = this.canAccessRessource(this.accessType).subscribe((canAccess) => {
      const element = this.el.nativeElement as HTMLDivElement;
      this.disabled = !canAccess;
      if (this.disabledClass) {
        if (!canAccess) {
          element.classList.add(...this.disabledClass);
        } else {
          element.classList.remove(...this.disabledClass);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub && !this.sub.closed) this.sub.unsubscribe();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CanAccessGuard extends CanAccessBase {
  constructor(private router: Router, store: Store<fromUser.State>) {
    super(store);
  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canAccessRessource(route.data.accessType).pipe(
      map((value) => {
        if (!value) this.router.navigate(['/', 'fleet']);
        return value;
      })
    );
  }
}
