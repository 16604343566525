import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { ChartSet } from 'src/app/_shared/classes/ChartSet';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { ChartDefinitions } from 'src/app/_shared/services/chart-definitions.service';
import { ISeriesOptions, ITrendsChart } from 'src/app/_shared/classes/chart.interface';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { PhaseList } from 'src/app/_shared/classes/phase-list.interface';
import * as fromStore from 'src/app/_store/_reducers';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import { AlarmEventNoteRequest } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';

@Injectable({
  providedIn: 'root',
})
export class GraphManagerService extends OnDestroyMixin implements OnDestroy {
  unitOfTime: moment.unitOfTime.DurationConstructor;
  private mDateRangeStart: moment.Moment;
  private mDateRangeEnd: moment.Moment;
  private granularity: string;
  private interval: number;
  private numberOfTimeUnits: moment.DurationInputArg1 = 1;
  private chartSetContainer = {};
  private absoluteStartDate: number;

  private mAccountId;
  private mMeasurementPointId;
  // true if date range start is relative to date range end
  private mDateRangeRelative: boolean;
  private mp: MeasurementPoint;

  public set dateRangeRelative(flag: boolean) {
    this.mDateRangeRelative = flag;
  }

  public get dateRangeRelative(): boolean {
    return this.mDateRangeRelative;
  }

  public get accountId(): string {
    this.store
      .pipe(select(fromStore.getMp))
      .pipe(take(1))
      .subscribe((mp) => {
        this.mAccountId = mp.accountId.toString();
      });
    return this.mAccountId;
  }

  public set accountId(accountId: string) {
    this.mAccountId = accountId;
  }

  public get measurementPointId(): string {
    this.store
      .pipe(select(fromStore.getMp))
      .pipe(take(1))
      .subscribe((mp) => {
        this.mMeasurementPointId = mp.measurementPointId ? mp.measurementPointId.toString() : mp.roomId.toString();
      });
    return this.mMeasurementPointId;
  }

  public set measurementPointId(measurePointId: string) {
    this.mMeasurementPointId = measurePointId;
  }

  public get dateRangeEnd(): moment.Moment {
    return this.mDateRangeEnd;
  }

  public set dateRangeEnd(newEndDate: moment.Moment) {
    if (!newEndDate) {
      newEndDate = moment().tz(this.mp.timezone);
    }
    this.mDateRangeEnd = newEndDate.clone();
    if (!this.dateRangeRelative) {
      this.mDateRangeStart = newEndDate.clone().tz(this.mp.timezone).startOf(this.unitOfTime).utc();
    } else {
      this.mDateRangeStart = newEndDate.clone().subtract(this.numberOfTimeUnits, this.unitOfTime);
    }
    if (this.absoluteStartDate && this.mDateRangeStart.valueOf() < this.absoluteStartDate) {
      this.mDateRangeStart = moment(this.absoluteStartDate).tz(this.mp.timezone);
    }
  }

  public get dateRangeStart(): moment.Moment {
    return this.mDateRangeStart;
  }

  public set dateRangeStart(value: moment.Moment) {
    this.mDateRangeStart = value;
  }

  public set dateRangeEndTrends(value: moment.Moment) {
    this.mDateRangeEnd = value;
  }

  constructor(
    private dAPIService: DiligentApiService,
    private chartDefinitions: ChartDefinitions,
    private notificationsService: NotificationsService,
    private store: Store<fromStore.State>
  ) {
    super();

    this.store
      .pipe(select(fromStore.getMp))
      .pipe(untilComponentDestroyed(this))
      .subscribe((mp) => {
        this.mp = mp;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  newChartSet(setName: string, chartKeys: string[], from: string, phaseList?: Array<PhaseList>): void {
    this.chartSetContainer[setName] = new ChartSet(
      setName,
      chartKeys,
      this.chartDefinitions,
      this.dAPIService,
      phaseList,
      this.notificationsService,
      from,
      this.store
    );
  }

  resetChartSet(setName: string, isQubeScan?: boolean): void {
    if (this.chartSetContainer[setName]) {
      this.chartSetContainer[setName].clearChartData();
    } else {
      if (!isQubeScan) {
        this.newChartSet(setName, ['dashboard', 'pqEvents'], 'dashboard');
      }
    }
  }

  setStartDateBound(absoluteStartDate: number): void {
    this.absoluteStartDate = absoluteStartDate;
  }

  updateChartSet(
    setName: string,
    timezone: string,
    isInsite?: boolean,
    isQubeScan?: boolean,
    from?: string
  ): Observable<ISeriesOptions[][]> {
    this.resetChartSet(setName, isQubeScan);
    return this.chartSetContainer[setName].initializeChartSetData(
      this.dateRangeStart,
      this.dateRangeEnd,
      this.granularity,
      this.interval,
      this.measurementPointId,
      timezone,
      isInsite,
      from
    );
  }

  getChartSet(chartSetName: string): ITrendsChart[] {
    if (this.chartSetContainer[chartSetName]) return this.chartSetContainer[chartSetName].charts;

    return null;
  }

  updateGranularity(
    newGranularity: string,
    timeUnit: moment.unitOfTime.DurationConstructor,
    range,
    newInterval: number = 1
  ): void {
    this.granularity = newGranularity;
    this.interval = newInterval;
    this.unitOfTime = timeUnit;
    this.numberOfTimeUnits = range;
    if (range > 0) {
      this.dateRangeRelative = true;
    } else this.dateRangeRelative = false;
  }

  getSingleEventData(eventTypeId: number, eventMoment: moment.Moment): Observable<any> {
    const eventsRequest: AlarmEventNoteRequest = {
      measurementPointId: parseInt(this.measurementPointId),
      dateRangeStart: eventMoment.toDate(),
      dateRangeEnd: eventMoment.toDate(),
      includeRetired: false,
      offset: 0,
      count: 1,
      sorting: [{ column: 'triggeredWhen', desc: true }],
    };
    if (eventTypeId > 0) {
      eventsRequest.typeIds = ['e' + eventTypeId.toString()];
    }
    return this.dAPIService.getAlarmsEventsNotes(eventsRequest);
  }

  getSingleEventDataById(eventId: number): Observable<any> {
    return this.dAPIService.getAlarmsEventsNote('event', eventId.toString());
  }
}
